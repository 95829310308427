<template>
  <div class="checkbox-container">
    <span>Sel. Loja</span>
    <div v-for="(loja, index) in lojas" :key="index">
      <input type="checkbox" :value="loja" v-model="internalSelectedLojas" @change="emitSelectedLojas" />
      <label>{{ loja }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBoxCompras",
  props: {
    lojas: {
      type: Array,
      required: true
    },
    selectedLojas: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      internalSelectedLojas: this.selectedLojas.slice() // Inicializa com as lojas já selecionadas
    };
  },
  methods: {
    emitSelectedLojas() {
      this.$emit('update:selectedLojas', this.internalSelectedLojas);
    }
  },
  watch: {
    selectedLojas: {
      immediate: true,
      handler(newVal) {
        this.internalSelectedLojas = newVal.slice();
      }
    }
  }
};
</script>

<style scoped>
.checkbox-container {
  border: 1px solid #E0E0E0;
  padding: 10px;
  padding-left: 2px;
  border-radius: 6px;
  width: fit-content;
  margin: 10px auto;
  margin-left: 1px;
  height: 160px;
  overflow-y: auto;
}

.checkbox-item {
  margin-bottom: 10px;
}

input[type="checkbox"] {
  margin-right: 5px;
}
</style>
