<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Compras 2</h6>
          <hr class="my-4 horizontal dark" />

          <!--Mobile-->
          <div v-if="isMobile" class="mt-0 row">
            <div class="col-12">
              <compras2-mobile/>
            </div>
          </div>
          <!--FIM MOBILE-->

          <!--Web-->
          <div v-else class="mt-0 row">
            <div class="col-6">
              <div class="mt-0 row">
                <span id="titulo">Período Compras</span>
                <div class="col-6">
                  <label class="form-label">Data Inicial</label>
                  <flat-pickr id="data-inicial" v-model="datai" class="form-control datetimepicker"
                              placeholder="--/--/----" :config="config"></flat-pickr>
                </div>
                <div class="col-6">
                  <label class="form-label">Data Final</label>
                  <flat-pickr id="data-inicial" v-model="dataf" class="form-control datetimepicker"
                              placeholder="--/--/----" :config="config"></flat-pickr>
                </div>
                <hr class="my-2 horizontal" />
                <span id="titulo">Período Vendas</span>
                <div class="col-6">
                  <label class="form-label">Data Inicial</label>
                  <flat-pickr id="data-inicial" v-model="datai" class="form-control datetimepicker"
                              placeholder="--/--/----" :config="config"></flat-pickr>
                </div>
                <div class="col-6">
                  <label class="form-label">Data Final</label>
                  <flat-pickr id="data-inicial" v-model="dataf" class="form-control datetimepicker"
                              placeholder="--/--/----" :config="config"></flat-pickr>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="col-4">
                <check-box-compras :lojas="lojas" :selectedLojas="selectedLojas" @update:selectedLojas="updateSelectedLojas"/>
              </div>
              <div class="col-3">
                <search-filter />
              </div>
            </div>

          </div>
          <hr class="my-4 horizontal dark" />

          <div class="col-12 d-flex align-items-center justify-content-center">
              <button v-on:click="pesquisar()"
                type="button"
                name="button"
                class="m-3 btn bg-gradient-primary ms-4">
                <i class="fas fa-magnifying-glass"> </i>  Pesquisar
              </button>
          </div>
          <!--FIM DIV-->

          <!--GRID DE FOLHA 1 -->
          <div class="row mt-2">
            <div class="col-12">
              <grid-compras2-main :folha1="folha1"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import SearchFilter from './components/SearchFilter.vue';
import "flatpickr/dist/l10n/pt.js"; 
import GridCompras2Main from "./components/GridCompras2Main.vue";  
import Compras2Mobile from "./components/Compras2Mobile.vue";  
import CheckBoxCompras from "./components/CheckBoxCompras.vue";  
import API_URL from "../../api.js";
import Choices from "choices.js";
import Quill from "quill"; 
import { mask } from 'vue-the-mask';
import { mapMutations } from "vuex";
import { format } from 'date-fns';

export default {
  name: "FuncxFolha", 
  directives: {
    mask
  },
  components: {
    GridCompras2Main,
    Compras2Mobile,
    CheckBoxCompras,
    SearchFilter,
    flatPickr
  },
  data() {
    return {  
      isMobile: '',
      selectedStatus: 'competencia',
      lojas: [1, 2, 3, 4, 5],
      selectedLojas: [1, 2, 3, 4, 5], // Inicialize com lojas já selecionadas
      selectedSituacao: 'ativo',
      selectedRegime: 'clt',
      teste: [{}],
      folha1: [{}],
      datai: '',
      dataf: '',
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y',
        defaultDate: new Date()
      }
    };
  },
  methods: {   
    ...mapMutations(["navbarMinimize", "navbarMaximize", "toggleConfigurator"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    formataData(data) {
      const datae = data.toString();
      let primeiraBarra = datae.indexOf("/");
      let dia = datae.slice(0, primeiraBarra);
      let semDia = datae.slice(primeiraBarra + 1, datae.length);
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0, segundaBarra);
      let ano = semDia.slice(segundaBarra + 1, semDia.length);
      return `${ano}-${mes}-${dia}`;
    },
    pesquisar() {
      localStorage.token ? console.log("Existe Token") : this.$route.path.split("/authentication/signin/basic");

      this.$swal({
        title: "pesquisando...",
        didOpen: () => {
          this.$swal.showLoading();
          console.log("Lojas selecionadas: " + this.selectedLojas);
          this.folha1 = [{
            ljt: 0,
            ljr: 0,
            codigo: 17717,
            matr: 50,
            nome: 'Gustavo Oliveira',
            admissao: '01/10/2022',
            e1: '',
            dtexp1: '',
            f1: '',
            e2: '',
            dtexp2: '',
            f2: '',
            demissao: '',
            salario: '1000,00',
            cargo: 'analista',
            ativo: 'S'
          }];
          this.$swal.close();
          console.log("datai: " + this.formataData(this.datai) + " dataf: " + this.formataData(this.dataf));
          this.teste = API_URL;
        }
      });
    },
    updateSelectedLojas(selectedLojas) {
      this.selectedLojas = selectedLojas;
      console.log('Lojas selecionadas:', this.selectedLojas);
    }
  },
  created() { 
    console.log("created");
  },
  mounted() {
    this.isMobile = window.innerWidth < 576;
    this.datai = format(new Date(), 'dd/MM/yyyy');
    this.dataf = format(new Date(), 'dd/MM/yyyy');
    this.pesquisar();

    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow"
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true
          },
          {
            value: "Three",
            label: "Label Three"
          }
        ],
        "value",
        "label",
        false
      );
    }
  }
};
</script>  

<style>
.form-control.error {
  border-color: red;
}

.form-control.datetimepicker.error {
  border-color: red;
}
</style>
