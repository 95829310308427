<template>
  <div>
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      :checked="modelValue === value"
      @change="updateValue"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    id: String,
    name: String,
    value: String,
    label: String,
    modelValue: [String, Number],
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>

<style scoped>
/* Estilos opcionais */
</style>
