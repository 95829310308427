<template>
    <div class="search-filter">
      <input
        type="text"
        v-model="query"
        @input="filterNames"
        placeholder="Digite aqui..."
        class="search-input"
      />
      <ul v-if="filteredNames.length" class="suggestions-list">
        <li
          v-for="name in filteredNames"
          :key="name"
          class="suggestion-item"
          @click="selectName(name)"
        >
          {{ name }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        query: '',
        names: ['Gustavo', 'Guilherme', 'Gabriel', 'Giovanna', 'Gloria'],
        filteredNames: []
      };
    },
    methods: {
      filterNames() {
        this.filteredNames = this.names.filter(name =>
          name.toLowerCase().includes(this.query.toLowerCase())
        );
      },
      selectName(name) {
        console.log('Nome selecionado:', name);
        // Você pode adicionar mais lógica aqui, como atualizar o campo de entrada ou fazer outra ação
        this.query = name; // Atualiza o campo de entrada com o nome selecionado
        this.filteredNames = []; // Limpa a lista de sugestões
      }
    }
  };
  </script>
  
  <style scoped>
    .search-filter {
        width: 300px;
        margin: 0 auto;
        position: relative;
    }
    
    .search-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-sizing: border-box;
    }
    
    .suggestions-list {
        list-style: none;
        padding: 0;
        margin: 0;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 8px 8px;
        max-height: 150px;
        overflow-y: auto;
        position: absolute;
        width: 100%;
        background: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }
    
    .suggestion-item {
        padding: 10px;
        cursor: pointer;
    }
    
    .suggestion-item:hover {
        background-color: #f0f0f0;
    }
  </style>
  