<template>
  <div class="card">
    <div class="table-responsive" >
      <table id="datatable-search" class="table table-flush">
        <thead class="thead-light fixo">
          <tr>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
            > 
              <span>
                Seq 
              </span>
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"> 
              <span>
                cod 
              </span>
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"> 
              <span>
                cargo 
              </span>
            </th>
            <th v-for="(loja, index) in lojas" :key="'loja-' + index" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8">
              <span>{{ 'Loja ' + (index + 1) }}</span>
            </th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"> 
              <span>
                total 
              </span>
            </th>
          </tr>
        </thead>

        <!--ordenacao original-->
        <tbody v-if="this.ordenacao === 'original'" >
          <tr v-for="(folha,index) in folha2" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ index + 1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.cod }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.cargo }}</h6>
                  </div>
                </div>
              </td>
              <td v-for="(loja, lojaIndex) in lojas" :key="'loja-data-' + lojaIndex">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{ color: registroSelecionado === index ? '#00CC00' : '' }">{{ loja }}</h6>
                </div>
              </div>
            </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.total }}</h6>
                  </div>
                </div>
              </td>
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO original--> 

        
        <!--TOTALIZADORES--------------------------->
        <!--<tbody>
          <tr>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">TOTAL</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalItens }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalValor }}</h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>-->
      </table>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css' 
//import API_URL from "../../../api.js"
//import { decryptData } from '../../../api.js';
//import { DataTable } from "simple-datatables";

export default {
  name: "GridFuncxFolha",
  props: {
    folha2: {
      type: Array,
      required: true
    }, 
  },
  components: {
  
  },
  data() {
    return {
      vendasclass: [{}], 
      registroSelecionado: '',
      lojas: this.folha2.lojas
    };
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    },
    formataValor(valorOriginal) {
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      const numero = parseFloat(valorFormatado.replace(",", "."));
      const numeroFormatado = numero.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return numeroFormatado ;
    },
    heightMobile() {
      return window.innerWidth > 412 ? true : false
    }
  },
  watch: {
    folha2: {
      immediate: true,
      handler() {
        this.ordenacao = 'original';
        this.clicado = '';
        },
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    /*const dataTableSearch = new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: false,
      language: {
        search: "Buscar:",  
      }
    });*/
  },
};
</script>

<style>
  .fas.fa-trash-alt {
    color: red;
  }

  .pointer-cursor{
    cursor: pointer;
  }

  thead.fixo {
    position: sticky;
    top: 0;
    z-index: 999; /* Isso é opcional, se você tiver algum elemento com um z-index maior que sobreponha o cabeçalho, você pode aumentar esse valor */
  }
</style>
