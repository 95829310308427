<template>
  <div class="card">
    <div class="table-responsive" >
      <table id="datatable-search" class="table table-flush">
        <thead class="thead-light fixo">
          <tr>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('linha')"
              :style="{background: (clicado === 'linha') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'linha') ? 'blue' : '' }">
                Linha <i v-if="clicado === 'linha'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('vendas')"
              :style="{background: (clicado === 'vendas') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'vendas') ? 'blue' : '' }">
                vendas <i v-if="clicado === 'vendas'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span> 
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('gir07')"
              :style="{background: (clicado === 'gir07') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'gir07') ? 'blue' : '' }">
                Gir07 <i v-if="clicado === 'gir07'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('giro21')"
              :style="{background: (clicado === 'giro21') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'giro21') ? 'blue' : '' }">
                Giro21 <i v-if="clicado === 'giro21'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('giro35')"
              :style="{background: (clicado === 'Giro35') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'giro35') ? 'blue' : '' }">
                Giro35 <i v-if="clicado === 'giro35'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('giro>35')"
              :style="{background: (clicado === 'giro>35') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'giro>35') ? 'blue' : '' }">
                Giro>35 <i v-if="clicado === 'giro>35'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('sallj')"
              :style="{background: (clicado === 'sallj') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'sallj') ? 'blue' : '' }">
                Sal.Lj <i v-if="clicado === 'sallj'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('salout')"
              :style="{background: (clicado === 'salout') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'salout') ? 'blue' : '' }">
                Sal.Out<i v-if="clicado === 'salout'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('cpraslj')"
              :style="{background: (clicado === 'cpraslj') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'cpraslj') ? 'blue' : '' }">
                Cpras.Lj <i v-if="clicado === 'cpraslj'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('cprasout')" 
              :style="{background: (clicado === 'cprasout') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'cprasout') ? 'blue' : '' }">
                Cpras.Out <i v-if="clicado === 'cprasout'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('cprastot')" 
              :style="{background: (clicado === 'cprastot') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'cprastot') ? 'blue' : '' }">
                Cpras.Tot <i v-if="clicado === 'cprastot'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('pedlj')"
              :style="{background: (clicado === 'pedlj') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'pedlj') ? 'blue' : '' }">
                Ped.Lj <i v-if="clicado === 'pedlj'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('pedout')"
              :style="{background: (clicado === 'pedout') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'pedout') ? 'blue' : '' }">
                Ped.Out <i v-if="clicado === 'pedout'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('pedtot')"
              :style="{background: (clicado === 'pedtot') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'pedtot') ? 'blue' : '' }">
                Ped.Tot <i v-if="clicado === 'pedtot'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('defsel')"
              :style="{background: (clicado === 'defsel') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'defsel') ? 'blue' : '' }">
                Def.Sel <i v-if="clicado === 'defsel'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('marliq')"
              :style="{background: (clicado === 'marliq') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'marliq') ? 'blue' : '' }">
                mar.liq <i v-if="clicado === 'marliq'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('totcpracus')"
              :style="{background: (clicado === 'totcpracus') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'totcpracus') ? 'blue' : '' }">
                tot.cpra.cus <i v-if="clicado === 'totcpracus'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('totvendas')"
              :style="{background: (clicado === 'totvendas') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'totvendas') ? 'blue' : '' }">
                tot.vendas <i v-if="clicado === 'totvendas'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('roi')"
              :style="{background: (clicado === 'roi') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'roi') ? 'blue' : '' }">
                roi <i v-if="clicado === 'roi'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('roi0')"
              :style="{background: (clicado === 'roi0') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'roi0') ? 'blue' : '' }">
                roi0 <i v-if="clicado === 'roi0'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('marplan')"
              :style="{background: (clicado === 'marplan') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'marplan') ? 'blue' : '' }">
                mar.plan <i v-if="clicado === 'marplan'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('totcusto')"
              :style="{background: (clicado === 'totcusto') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'totcusto') ? 'blue' : '' }">
                tot.custo <i v-if="clicado === 'totcusto'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('totvdabruto')"
              :style="{background: (clicado === 'totvdabruto') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'totvdabruto') ? 'blue' : '' }">
                tot.vda.bruto <i v-if="clicado === 'totvdabruto'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('totcpraven')"
              :style="{background: (clicado === 'totcpraven') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'totcpraven') ? 'blue' : '' }">
                tot.cpra.ven <i v-if="clicado === 'totcpraven'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
          </tr>
        </thead>

        <!--ordenacao original-->
        <tbody v-if="this.ordenacao === 'original'" >
          <tr v-for="(folha,index) in folha1" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Linha }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Vendas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Gir07 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro21 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro35 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro35 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.sallj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.salout }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasLj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasOut }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasTot }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedlj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedout }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedtot }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.marliq) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcpracus) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totvendas) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.roi) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.roi0 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.marplan) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcusto) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totvdabruto) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcpraven) }}</h6>
                  </div>
                </div>
              </td> 
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO original--> 

        <!--ordenacao loja-->
        <tbody v-if="this.ordenacao === 'loja'" >
            <tr v-for="(folha,index) in ordenacaoLoja" :key="index"
             v-on:click="selecionado(index)" >
             <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Linha }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Vendas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Gir07 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro21 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro35 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro35 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.sallj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.salout }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasLj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasOut }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasTot }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedlj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedout }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedtot }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.marliq) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcpracus) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totvendas) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.roi) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.roi0 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.marplan) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcusto) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totvdabruto) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcpraven) }}</h6>
                  </div>
                </div>
              </td> 
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO LOJA-->

        <!--ordenacao OUTRO-->
        <tbody v-if="this.ordenacao === 'outro'" >
          <tr v-for="(folha,index) in ordenacaoDecr" :key="index"
             v-on:click="selecionado(index)" >
             <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Linha }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Vendas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Gir07 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro21 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro35 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.Giro35 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.sallj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.salout }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasLj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasOut }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.CprasTot }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedlj }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedout }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.pedtot }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.marliq) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcpracus) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totvendas) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.roi) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.roi0 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.marplan) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcusto) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totvdabruto) }}</h6>
                  </div>
                </div>
              </td> 
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.totcpraven) }}</h6>
                  </div>
                </div>
              </td> 
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO OUTRO-->
        
        <!--TOTALIZADORES--------------------------->
        <!--<tbody>
          <tr>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">TOTAL</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalItens }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalValor }}</h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>-->
      </table>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css' 
//import API_URL from "../../../api.js"
//import { decryptData } from '../../../api.js';
//import { DataTable } from "simple-datatables";

export default {
  name: "GridCompras2Main",
  props: {
    folha1: {
      type: Array,
      required: true
    }, 
  },
  components: {
  
  },
  data() {
    return {
      vendasclass: [{}],
      ordenacao: 'original',
      clicado: '',
      registroSelecionado: '',
      ordenacaoDecr: '',
      ordenacaoLoja: '',
      isMouseOverTitle: false,
    };
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    ordenarDecr(campo) {
      this.ordenacao = 'outro'
      if(campo === 'codigo'){
        this.clicado = 'codigo'
      }else if (campo === 'matr'){
        this.clicado = 'matr'
      }else if (campo === 'nome'){
        this.clicado = 'nome'
      }else if (campo === 'admissao'){
        this.clicado = 'admissao'
      }else if (campo === 'e1'){
        this.clicado = 'e1'
      }else if (campo === 'dtexp1'){
        this.clicado = 'dtexp1'
      }else if (campo === 'f1'){
        this.clicado = 'f1'
      }else if (campo === 'e2'){
        this.clicado = 'e2'
      }else if (campo === 'dtexp2'){
        this.clicado = 'dtexp2'
      }else if (campo === 'f2'){
        this.clicado = 'f2'
      }else if (campo === 'demissao'){
        this.clicado = 'demissao'
      }else if (campo === 'salario'){
        this.clicado = 'salario'
      }else if (campo === 'cargo'){
        this.clicado = 'cargo'
      }else if (campo === 'ativo'){
        this.clicado = 'ativo'
      }else{
        this.clicado = 'original'
      }

      this.ordenacaoDecr = [...this.folha1];

      const elementosFiltrados = [...this.folha1].filter(fol => fol[campo] !== null && fol[campo] !== 0);
 
      const elementosOrdenados = [...this.folha1].filter(fol => fol[campo] === null || fol[campo] === 0);

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return -1;
      }
      if (valorA < valorB) {
        return 1;
      }
      return 0;
      });

      this.ordenacaoDecr = elementosFiltrados.concat(elementosOrdenados); 
    },  
    ordenarPorLoja(campo) {
      this.ordenacao = campo
      if(campo === 'ljt'){
        this.clicado = 'ljt'
      }else if (campo === 'ljr'){
        this.clicado = 'ljr'
      }else{
        this.clicado = 'original'
      }

      this.ordenacaoLoja = [...this.folha1];

      const elementosFiltrados = [...this.folha1].filter(fat => fat[campo] !== null && fat[campo] !== 0);
 
      const elementosOrdenados = [...this.folha1].filter(fat => fat[campo] === null || fat[campo] === 0);

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return 1;
      }
      if (valorA < valorB) {
        return -1;
      }
      return 0;
      });

      this.ordenacaoLoja = elementosFiltrados.concat(elementosOrdenados); 
    }, 
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    },
    formataValor(valorOriginal) {
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      const numero = parseFloat(valorFormatado.replace(",", "."));
      const numeroFormatado = numero.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return numeroFormatado ;
    },
    heightMobile() {
      return window.innerWidth > 412 ? true : false
    }
  },
  watch: {
    folha1: {
      immediate: true,
      handler() {
        this.ordenacao = 'original';
        this.clicado = '';
        },
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    /*const dataTableSearch = new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: false,
      language: {
        search: "Buscar:",  
      }
    });*/
  },
};
</script>

<style>
  .fas.fa-trash-alt {
    color: red;
  }

  .pointer-cursor{
    cursor: pointer;
  }

  thead.fixo {
    position: sticky;
    top: 0;
    z-index: 999; /* Isso é opcional, se você tiver algum elemento com um z-index maior que sobreponha o cabeçalho, você pode aumentar esse valor */
  }
</style>
