<template>
    <div class="col-12">
       <span id="titulo">Período Compras</span>
        <div class="col-12">
            <label class="form-label">Data Inicial</label>
            <flat-pickr id="data-inicial" v-model="datai" class="form-control datetimepicker"
            placeholder="--/--/----" :config="config"></flat-pickr>
        </div>
        <div class="col-12">
            <label class="form-label">Data Final</label>
            <flat-pickr id="data-final" v-model="dataf" class="form-control datetimepicker"
            placeholder="--/--/----" :config="config"></flat-pickr>
        </div>
        <hr class="my-2 horizontal" />
        <span id="titulo">Período Vendas</span>
        <div class="col-12">
          <label class="form-label">Data Inicial</label>
          <flat-pickr id="data-inicial" v-model="datai" class="form-control datetimepicker"
                      placeholder="--/--/----" :config="config"></flat-pickr>
        </div>
        <div class="col-12">
          <label class="form-label">Data Final</label>
          <flat-pickr id="data-inicial" v-model="dataf" class="form-control datetimepicker"
                      placeholder="--/--/----" :config="config"></flat-pickr>
        </div>

        <!--<div class="col-12 d-flex align-items-center justify-content-center">
            <button v-on:click="pesquisar()"
            type="button"
            name="button"
            class="m-4 btn bg-gradient-primary ms-1"
            >
            <i class="fas fa-magnifying-glass"> </i>  Pesquisar
            </button>
        </div>-->
    </div>
  </template>
  
  <script>
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/l10n/pt.js"; 
  
  export default {
    name: 'Compras2Mobile',
    components: {
        flatPickr
    },
  data() {
    return {  
      dataiCompra: '',
      datafCompra: '',
      dataiVenda: '',
      datafVenda: '',
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y',
        defaultDate: new Date()
      },
    };
  },
  };
  </script>
  
  <style> 
  </style>
  