<template>
  <div class="card">
    <div class="table-responsive" >
      <table id="datatable-search" class="table table-flush">
        <thead class="thead-light fixo">
          <tr>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
            > 
              <span>
                Seq 
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarPorLoja()"
              :style="{background: (clicado === 'ljt') ? 'white' : '',
                      borderRadius: (clicado === 'ljt') ? '12px 0 0 0' : '',}"
            > 
              <span :style="{ color: (clicado === 'ljt') ? 'blue' : ''}">
                LjT <i v-if="clicado === 'ljt'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarPorLoja()"
              :style="{background: (clicado === 'ljr') ? 'white' : '',
                      borderRadius: (clicado === 'ljr') ? '12px 0 0 0' : '',}"
            > 
              <span :style="{ color: (clicado === 'ljr') ? 'blue' : ''}">
                LjR <i v-if="clicado === 'ljr'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('codigo')"
              :style="{background: (clicado === 'codigo') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'codigo') ? 'blue' : '' }">
                Código <i v-if="clicado === 'codigo'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('matr')"
              :style="{background: (clicado === 'matr') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'matr') ? 'blue' : '' }">
                Matr <i v-if="clicado === 'matr'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span> 
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('nome')"
              :style="{background: (clicado === 'nome') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'nome') ? 'blue' : '' }">
                Nome <i v-if="clicado === 'nome'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('admissao')"
              :style="{background: (clicado === 'admissao') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'admissao') ? 'blue' : '' }">
                Admissao <i v-if="clicado === 'admissao'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('e1')"
              :style="{background: (clicado === 'e1') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'e1') ? 'blue' : '' }">
                E1 <i v-if="clicado === 'e1'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dtexp1')"
              :style="{background: (clicado === 'dtexp1') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'dtexp1') ? 'blue' : '' }">
                dtexp1 <i v-if="clicado === 'dtexp1'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('f1')"
              :style="{background: (clicado === 'f1') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'f1') ? 'blue' : '' }">
                F1 <i v-if="clicado === 'f1'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('e2')"
              :style="{background: (clicado === 'e2') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'e2') ? 'blue' : '' }">
                E2<i v-if="clicado === 'e2'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dtexp2')"
              :style="{background: (clicado === 'dtexp2') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dtexp2') ? 'blue' : '' }">
                dtexp2 <i v-if="clicado === 'dtexp2'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('f2')" 
              :style="{background: (clicado === 'f2') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'f2') ? 'blue' : '' }">
                f2 <i v-if="clicado === 'f2'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('demissao')" 
              :style="{background: (clicado === 'demissao') ? 'white' : '',
                      borderRadius: (clicado === 'demissao') ? '0 12px 0 0' : ''}"
            > 
              <span :style="{ color: (clicado === 'demissao') ? 'blue' : '' }">
                demissao <i v-if="clicado === 'demissao'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('salario')" 
              :style="{background: (clicado === 'salario') ? 'white' : '',
                      borderRadius: (clicado === 'salario') ? '0 12px 0 0' : ''}"
            > 
              <span :style="{ color: (clicado === 'salario') ? 'blue' : '' }">
                salário <i v-if="clicado === 'salario'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('cargo')"
              :style="{background: (clicado === 'cargo') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'cargo') ? 'blue' : '' }">
                cargo <i v-if="clicado === 'cargo'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('ativo')"
              :style="{background: (clicado === 'ativo') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'ativo') ? 'blue' : '' }">
                ativo <i v-if="clicado === 'ativo'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
          </tr>
        </thead>

        <!--ordenacao original-->
        <tbody v-if="this.ordenacao === 'original'" >
          <tr v-for="(folha,index) in folha1" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ index + 1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ljt }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ljr }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.codigo }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.matr }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.nome }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.admissao }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.dtexp1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.f1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.dtexp2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.f2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.demissao }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.salario) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.cargo }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ativo }}</h6>
                  </div>
                </div>
              </td>
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO original--> 

        <!--ordenacao loja-->
        <tbody v-if="this.ordenacao === 'loja'" >
            <tr v-for="(folha,index) in ordenacaoLoja" :key="index"
             v-on:click="selecionado(index)" >
             <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ index + 1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ljt }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ljr }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.codigo }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.matr }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.nome }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.admissao }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.dtexp1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.f1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.dtexp2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.f2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.demissao }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.salario) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.cargo }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ativo }}</h6>
                  </div>
                </div>
              </td>
            </tr>
        </tbody>
        <!--FIM ORDENAÇÃO LOJA-->

        <!--ordenacao OUTRO-->
        <tbody v-if="this.ordenacao === 'outro'" >
          <tr v-for="(folha,index) in ordenacaoDecr" :key="index"
             v-on:click="selecionado(index)" >
             <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ index + 1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ljt }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ljr }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.codigo }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.matr }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.nome }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.admissao }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.dtexp1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.f1 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.e2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.dtexp2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.f2 }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.demissao }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(folha.salario) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.cargo }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ folha.ativo }}</h6>
                  </div>
                </div>
              </td>
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO OUTRO-->
        
        <!--TOTALIZADORES--------------------------->
        <!--<tbody>
          <tr>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">TOTAL</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalItens }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalValor }}</h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>-->
      </table>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css' 
//import API_URL from "../../../api.js"
//import { decryptData } from '../../../api.js';
//import { DataTable } from "simple-datatables";

export default {
  name: "GridFuncxFolha",
  props: {
    folha1: {
      type: Array,
      required: true
    }, 
  },
  components: {
  
  },
  data() {
    return {
      vendasclass: [{}],
      ordenacao: 'original',
      clicado: '',
      registroSelecionado: '',
      ordenacaoDecr: '',
      ordenacaoLoja: '',
      isMouseOverTitle: false,
    };
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    ordenarDecr(campo) {
      this.ordenacao = 'outro'
      if(campo === 'codigo'){
        this.clicado = 'codigo'
      }else if (campo === 'matr'){
        this.clicado = 'matr'
      }else if (campo === 'nome'){
        this.clicado = 'nome'
      }else if (campo === 'admissao'){
        this.clicado = 'admissao'
      }else if (campo === 'e1'){
        this.clicado = 'e1'
      }else if (campo === 'dtexp1'){
        this.clicado = 'dtexp1'
      }else if (campo === 'f1'){
        this.clicado = 'f1'
      }else if (campo === 'e2'){
        this.clicado = 'e2'
      }else if (campo === 'dtexp2'){
        this.clicado = 'dtexp2'
      }else if (campo === 'f2'){
        this.clicado = 'f2'
      }else if (campo === 'demissao'){
        this.clicado = 'demissao'
      }else if (campo === 'salario'){
        this.clicado = 'salario'
      }else if (campo === 'cargo'){
        this.clicado = 'cargo'
      }else if (campo === 'ativo'){
        this.clicado = 'ativo'
      }else{
        this.clicado = 'original'
      }

      this.ordenacaoDecr = [...this.folha1];

      const elementosFiltrados = [...this.folha1].filter(fol => fol[campo] !== null && fol[campo] !== 0);
 
      const elementosOrdenados = [...this.folha1].filter(fol => fol[campo] === null || fol[campo] === 0);

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return -1;
      }
      if (valorA < valorB) {
        return 1;
      }
      return 0;
      });

      this.ordenacaoDecr = elementosFiltrados.concat(elementosOrdenados); 
    },  
    ordenarPorLoja(campo) {
      this.ordenacao = campo
      if(campo === 'ljt'){
        this.clicado = 'ljt'
      }else if (campo === 'ljr'){
        this.clicado = 'ljr'
      }else{
        this.clicado = 'original'
      }

      this.ordenacaoLoja = [...this.folha1];

      const elementosFiltrados = [...this.folha1].filter(fat => fat[campo] !== null && fat[campo] !== 0);
 
      const elementosOrdenados = [...this.folha1].filter(fat => fat[campo] === null || fat[campo] === 0);

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return 1;
      }
      if (valorA < valorB) {
        return -1;
      }
      return 0;
      });

      this.ordenacaoLoja = elementosFiltrados.concat(elementosOrdenados); 
    }, 
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    },
    formataValor(valorOriginal) {
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      const numero = parseFloat(valorFormatado.replace(",", "."));
      const numeroFormatado = numero.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return numeroFormatado ;
    },
    heightMobile() {
      return window.innerWidth > 412 ? true : false
    }
  },
  watch: {
    folha1: {
      immediate: true,
      handler() {
        this.ordenacao = 'original';
        this.clicado = '';
        },
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    /*const dataTableSearch = new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: false,
      language: {
        search: "Buscar:",  
      }
    });*/
  },
};
</script>

<style>
  .fas.fa-trash-alt {
    color: red;
  }

  .pointer-cursor{
    cursor: pointer;
  }

  thead.fixo {
    position: sticky;
    top: 0;
    z-index: 999; /* Isso é opcional, se você tiver algum elemento com um z-index maior que sobreponha o cabeçalho, você pode aumentar esse valor */
  }
</style>
